import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import Logo from '../../images/logo.png'
import LogoMini from '../../images/logo_mini.png'
import kouhao from '../../images/kouhao.jpg'
import { useLocation, useNavigate } from 'react-router-dom'
import { router } from '../../router'

import detailBanner from '../../images/detail_banner.png'
import detail_zixun_banner from '../../images/detail_zixun_banner.png'
import detail_gongjv_banner from '../../images/detail_gongjv_banner.png'

import './index.less'

const { Header } = Layout;

const headerStyle = {
  minWidth: 1160,
  textAlign: 'center',
  color: '#fff',
  height: 90,
  padding: 0,
  lineHeight: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

const HeaderCom = () =>{
  const location = useLocation();
  const navigate  = useNavigate()

  const [state, setState] = useState(false)
  const [path, setPath] = useState('')

  useEffect(()=>{
    console.log('111111111111111111111111111111111')
    if(location.pathname === '/home' || location.pathname === '/' || location.pathname === '' ){
      setState(false)
    }else{
      setState(true)
    }
    setPath(location.pathname)
    console.log(location.pathname)
  },[location.pathname])

  const renderDetailBanner = () =>{
    if(path === '/newsAndAnnouncement'){
      return detail_zixun_banner
    }else if(path === '/nutritionTools'){
      return detail_gongjv_banner
    }else{
      return detailBanner
    }
  }

  return (
    <Header style={state? {...headerStyle, height:'auto'}:{...headerStyle}}>
      {/* <div style={{width: 1160, height:84, marginTop:20, textAlign: 'left'}}>
        <img src={Logo} alt=''/>
      </div> */}
      <div className="nav">
        {/* <div className='name'>中国营养师网</div> */} 
        <div className='name'>
          <img style={{height: 60}} src={LogoMini} alt=''/>
          {/* <div className='logo'></div> */}
          <div>
            <div className='name-text'>
              中国营养师网 
            </div>
            <div>
              CHINESE CERTIFIED DIETITIAN 
            </div>
          </div>
        </div> 
        <ul>
          {router.map((e,i)=>{
            if(e.external){
              return (
                <a key={i} href={e.path} target="_blank">
                  <li>
                    {e.name}
                  </li>
                </a>
              )
            }else{
              if(e.path === '/home' && location.pathname === '/'){
                return (
                  <li 
                    key={i} 
                    className="action"
                    onClick={()=>navigate(e.path)}
                  >
                    {e.name}
                  </li>
                )
              }else if(e.key === 'abilityPromotion'){
                return (
                  <li 
                    key={i}
                    className={location.pathname === e.path? "action":''}
                    onClick={()=>alert('敬请期待')}
                  >
                    {e.name}
                  </li>
                )
              }else {
                return (
                  <li 
                    key={i}
                    className={location.pathname === e.path? "action":''}
                    onClick={()=>navigate(e.path)}
                  >
                    {e.name}
                  </li>
                )
              }
            }
          })}
        </ul>
      </div>
      {state? <img style={{width:'100%'}} src={renderDetailBanner()} alt="" />:''}
      
    </Header>
  )
}

export default HeaderCom