import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { router } from '../../router'
import { List } from 'antd';
import { HomeFilled } from '@ant-design/icons';

import emptyImg from '../../images/logo.deddde2.png'
import detailBanner from '../../images/detail_banner.png'

import hangye from '../../images/hangye.png'
import guoji from '../../images/guoji.png'
 
import { 
  newsData, 
  NoticeData,

  policy,
  expertConsensus,
  technicalStandard,

  continuingEducation,
  teacherTraining,
  nutritionSciencePopularization, 

  assessmentConsultation,

  DRIs,
  dietaryGuidelines,
  other,


  videoDietaryGuidelines,
  videoDRIS,

} from '../../components/mokeData'

import Axios from '../../components/BaseRequest.ts'

import './index.less'
import './quill.snow.css'


const Content = () =>{
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [findRouter, setFindRouter] = useState({})
  const [selectTag, setSelectTag] = useState({})
  const [selectArticle, setSelectArticle] = useState({})
  const [articleIndex, setArticleIndex] = useState(0)

  const [selectAllData, setSelectAllData] = useState([])
  

  const [tagData, setTagData] = useState({
    'newsAndAnnouncement':[
      // {name: '新闻', key: 'news', data: newsData},
      // {name: '公告', key: 'Notice', data: NoticeData}
      {name: '行业动态', key: 'Notice', data: [], defaultImg: hangye},
      {name: '国际资讯', key: 'news', data: [], defaultImg: guoji},
    ],
    'policyStandards':[
      // {name: '政策法规', key: 'policy', data: policy},
      // {name: '专家共识', key: 'expertConsensus', data: expertConsensus},
      // {name: '技术标准', key: 'technicalStandard', data: technicalStandard},
      {name: '政策法规', key: 'policy', data: []},
      {name: '专家共识', key: 'expertConsensus', data: []},
      {name: '技术标准', key: 'technicalStandard', data: []},
    ],
    'abilityPromotion':[
      // {name: '继续教育', key: 'continuingEducation', data: continuingEducation},
      // {name: '师资进修', key: 'teacherTraining', data: teacherTraining},
      // {name: '营养科普', key: 'nutritionSciencePopularization', data: nutritionSciencePopularization},
      {name: '继续教育', key: 'continuingEducation', data: []},
      {name: '师资进修', key: 'teacherTraining', data: []},
      {name: '营养科普', key: 'nutritionSciencePopularization', data: []},
    ],
    'publicNutritionist':[
      {name: '成绩查询', key: '', link: 'http://score.dietitian.net/scoreService/query'},
      {name: '证书查询', key: '', link: 'http://cert.dietitian.net/certService/query'},
      {name: '考核咨询', key: 'assessmentConsultation', data: assessmentConsultation},
      {name: '考试报名', key: '', link: 'http://sign.dietitian.net/login'}
    ],
    'nutritionTools':[
      // {name: 'DRIs', key: 'DRIs', data: []},
      // {name: '膳食指南', key: 'dietaryGuidelines', data: dietaryGuidelines},
      {
        name: '营养科学信息资源库', 
        key: '', 
        link: 'https://www.nutritionsciences.org.cn',
        content:[
          <div style={{
            borderBottom: '1px solid #000',
            lineHeight: '30px',
            fontWeight:900
          }}>
            营养科学信息资源库
          </div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.nutritionsciences.org.cn'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              “营养科学信息资源库”（简称“资源库”）
            </a>
            整合学会、内部和外部等技术资源，与先进的数据库开发机构合作，建立营养科学信息资源平台，资源库包含学术报告厅、指南与共识、案例报告、法规标准、著作与教材、营养数据库（外接）六大核心板块，以满足不同层面的信息需求。
          </div>,
          <div style={{margin: '10px 0'}}>
            1．学术报告厅，汇聚了国内外营养学领域的最新研究成果和前沿动态，为科技工作者提供了一个知识交流平台。
          </div>,
          <div style={{margin: '10px 0'}}>
            2．指南与共识板块，囊括了国内外营养相关的指南、共识、团标及科学循证等资料，为科技工作者提供了权威、专业的参考依据。
          </div>,
          <div style={{margin: '10px 0'}}>
            3．案例报告板块，是科技工作者和临床医生分享成功案例交流的板块（包括干预、治疗及配餐等方面取得的成功经验），为同行提供借鉴和启示。
          </div>,
          <div style={{margin: '10px 0'}}>
            4．法规标准板块，包括国内、国际的法规政策和国内外营养相关的标准，为科技工作者在实践中提供法规和标准支持。
          </div>,
          <div style={{margin: '10px 0'}}>
            5．著作与教材版块，作为该资源库的一个重要组成部分，主要聚焦于营养科学领域的经典著作、教材及相关参考资料的整合与展示。
          </div>,
          <div style={{margin: '10px 0'}}>
            6．营养数据库板块，该板块不仅链接了国内外各大营养相关数据网站，还包含了人群营养监测数据。这一丰富的数据资源，极大地促进了科技工作者间的业务交流和提升，为营养学研究和实践应用提供了强有力的数据支持。现由会员部负责日常运转。
          </div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.nutritionsciences.org.cn'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              点击访问：营养科学信息资源库（https://www.nutritionsciences.org.cn）
            </a>
          </div>
      
        ]
      },
      {
        name: 'NutriData', 
        key: '', 
        link: 'https://www.nutridata.cn/home',
        content: [
          <div style={{
            borderBottom: '1px solid #000',
            lineHeight: '30px',
            fontWeight:900
          }}>
            NutriData
          </div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.nutridata.cn/home'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              NutriData营养综合信息数据库
            </a>
            是国内针对营养领域的领先的综合性数据平台，由中国营养学会营养大数据与健康分会和中国健康促进基金会营养专项基金提供学术支持，由北京邦尼营策科技有限公司负责具体建设和运营。
          </div>,
          <div style={{margin: '10px 0'}}>
            NutriData平台数据源于专家团队多年积累，不仅收录了国内外权威机构发布和检测的食物成分数据（包括食材、菜肴、婴配奶粉、营养素补充剂、药食同源、保健食品等），还包含自建的营养相关数据，如母乳成分数据库、GI 数据库、预包装食品库等。根据营养学原理，搭建运算逻辑，融合云计算、人工智能、区块链等多元技术手段，形成了 20 余个食物类营养数据库，支持 API 接口形式访问。目前已经在智慧膳食管理系统、营养知识智能体、人群营养监测及干预研究等多方面开展了应用实践。
          </div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.nutridata.cn/home'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              点击访问：NutriData营养综合信息数据库（https://www.nutridata.cn/home）
            </a>
          </div>
        ]
      },
      {
        name: '配餐工具', 
        key: '', 
        notes: 'https://www.dyhomedr.com/#/user/public',
        content: [
          <div style={{
            borderBottom: '1px solid #000',
            lineHeight: '30px',
            fontWeight:900
          }}>
            配餐工具
          </div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.dyhomedr.com/#/user/public'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              大营家营养师智慧膳食管理系统
            </a>
            是一款由中国营养学会专家指导、中国健康促进基金会专项基金支持的智慧膳食管理系统。该系统不仅被用作人社部公共营养师培训的指定教具及健康管理机构营养师规范化认证培训的必修课程，同时还是全国营养师技能大赛总决赛的实操考试专用工具，已经为2万余名专业营养工作者提供服务并深受广大营养师的喜爱。
          </div>,
          <div style={{margin: '10px 0'}}>
            该系统具备食物营养查询、用户管理、膳食调查和评估、智能配餐、健康管理小工具等多个功能模块，可以有效支持减肥减重、血糖控制、孕妇乳母等特殊需求人群，以及幼儿园、中小学、养老院、健康管理机构等多场景的膳食管理及饮食指导工作
          </div>,
          <div style={{margin: '10px 0'}}>食物查询功能：提供了丰富的食材、菜肴、预包装食品、保健食品的全生命周期食物相关数据查询功能。</div>,
          <div style={{margin: '10px 0'}}>用户管理功能：支持营养师建立电子用户档案，实现了与小程序的联动使用，支持远程膳食管理的便捷性和高效性。</div>,
          <div style={{margin: '10px 0'}}>膳食评估功能：支持各人群从膳食结构、食物种类丰富度、粉类食材提供量、营养素含量等多维度评估。</div>,
          <div style={{margin: '10px 0'}}>智能配餐功能：配餐功能支持一键智能配餐和调取模版配餐，营养师可以根据用户信息为不同人群制定膳食方案。</div>,
          <div style={{margin: '10px 0'}}>健康管理小工具：包括身体质量指数、基础代谢、运动消耗、身体活动水平、膳食营养查询等。</div>,
          <div style={{margin: '20px 0'}}>
            <a href={'https://www.dyhomedr.com/#/user/public'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              点击访问：大营家营养师智慧膳食管理系统（https://www.dyhomedr.com/#/user/public）
            </a>
          </div>
        ]
      },
      {
        name: '膳食指南工具包', 
        key: '', 
        link: 'https://www.cnsedu.net/tool/toolkit',
        content: [
          <div style={{
            borderBottom: '1px solid #000',
            lineHeight: '30px',
            fontWeight:900
          }}>
            膳食指南工具包
          </div>,
          <div style={{margin: '20px 0'}}>
            在国家卫生健康委员会的组织和领导下，《中国居民膳食指南(2022)》第5版于2022年发布。《中国居民膳食指南(2022)》包含2岁以上大众膳食指南，以及9个特定人群指南。为方便百姓应用，还修订完成《国居民膳食指南(2022)》科普版，帮助百姓做出有益健康的饮食选择和行为改变。同时还修订完成了中国居民平衡膳食宝塔(2022)、中国居民平膳食餐盘 2022) 和儿童平衡膳食算盘(2022) 等可视化图形，指导大众在日常生活中进行体实践。
          </div>,
          <div style={{margin: '50px 0'}}>
            点击访问：<a href={'https://www.cnsedu.net/tool/toolkit'} style={{color:'rgb(0, 102, 204)'}} target="_blank">
              膳食指南工具包（https://www.cnsedu.net/tool/toolkit）
            </a>
          </div>
        ]
      },
      // {name: '全民营养周工具包', key: '', link: 'https://www.cnsoc.org/background/'},
      // {name: '其他', key: 'other', data: other},
    ],
    'videoZone':[
      // {name: '膳食指南', key: 'videoDietaryGuidelines', data: videoDietaryGuidelines},
      // {name: 'DRIS', key: 'videoDRIS', data: videoDRIS},
      {name: '膳食指南', key: 'videoDietaryGuidelines', data: []},
      {name: 'DRIS', key: 'videoDRIS', data: []},
    ]
  })
  
  useEffect(()=>{
    console.log(location)
    console.log(router.find(e=> e.path === location.pathname))
    const findObj = router.find(e=> e.path === location.pathname)
    console.log('findObj', findObj)
    setFindRouter(()=>findObj)
    if(location.state && location.state.tag){
      const tag = location.state.tag;
      const id = location.state.id;
      const findTag = tagData[findObj.key].find(e=> e.key === tag)
      console.log('findTag', findTag)
      setSelectTag(()=> findTag)
      setSelectArticle({id})
      // if(id && findTag){
      //   setSelectArticle(findTag.data.find(e=> e.id === id))
      //   setArticleIndex(findTag.data.findIndex(e=> e.id === id))
      // }
    }else{
      if(findObj.key === "nutritionTools"){
        setSelectTag(tagData[findObj.key][0])
      }else{
        setSelectTag({})
      }
      setSelectArticle({})
      setArticleIndex(0)
    }

  },[location.pathname])

  useEffect(()=>{
    // setSelectTag({})
    getContentDataFn(selectTag?.key)
    console.log(findRouter, selectTag)
  },[findRouter.key])

  useEffect(()=>{
    if(selectTag.key){
      getContentDataFn(selectTag?.key)
      console.log(findRouter, selectTag)
    }
  },[selectTag?.key])

  // 获取文章列表
  const getContentDataFn = async (subType) =>{
    console.log('获取文章列表', findRouter, tagData[findRouter.key])
    if(findRouter.key){
      let res = await Axios('/nutri/list', {
        essayType: findRouter.key,
        essaySubType: subType? subType:'',
        pageSize: 999,
        pageNum: 1
      })
      console.log('获取文章列表', res.data.data)
      if(res.data.status === 200){
        let arr = res.data.data.map(e=>{
          let obj = {
            id: e.id,
            Key: e.essaySubType,
            title: e.essayTitle,
            time: e.crtTm,
            img: e.essayImg,
            data:``
          }
          if(e.essaySubType === 'news'){
            return ({...obj, defaultImg: guoji})
          }else if(e.essaySubType === 'Notice'){
            return ({...obj, defaultImg: hangye})
          }else{
            return ({...obj})
          }
        })
        console.log(arr)
        console.log('findRouter', findRouter)
        console.log('selectTag', selectTag)
        let findTag = []
        if(selectTag?.key){
          tagData[findRouter.key].forEach(e=>{
            if(e.key === selectTag.key){
              e.data = arr
              findTag = e
            }
          })
          console.log(findTag)
          setTagData(tagData)
          setSelectAllData(()=> [])
          setSelectTag(()=> findTag)
        }else{
          setSelectAllData(()=> arr)
        }

        console.log(findTag)
        if(selectArticle.id && findTag.data){
          console.log(findTag.data)
          getContentDetailFn(selectArticle.id)
          setArticleIndex(findTag.data.findIndex(e=> e.id === selectArticle.id))
        }
        console.log(tagData[findRouter.key])
      }
    }
  }
  // 获取文章详情
  const getContentDetailFn = async (id) =>{
    console.log(id)
    if(selectTag.key){
      let res = await Axios('/nutri/info', {id})
      console.log('获取文章详情', res.data.data)
      if(res.data.status === 200 && res.data.data){
        setSelectArticle({
          id: res.data.data.id,
          Key: res.data.data.essaySubType,
          title: res.data.data.essayTitle,
          time: res.data.data.crtTm,
          img: res.data.data.essayImg,
          data: res.data.data.essayData
        })
      }
    }
  }

  return (
    <div>
      <div className='content-box'>
        <div className='breadcrumb-box'>
          <HomeFilled style={{color:'#30790f',marginRight: 10}} />当前位置：
          <span onClick={()=> navigate('/home')}>首页</span>
          {` > `}
          <span 
            onClick={()=> {
              // setSelectTag(tagData[findRouter.key][0])
              let arr = []
              console.log(tagData[findRouter.key])
              tagData[findRouter.key].forEach(e=>{
                if(e.data) {
                  e.data.forEach(E=> E.Key = e.key)
                  arr = arr.concat(e.data)
                }
              })
              setSelectAllData(arr)
              setSelectTag({})
              setSelectArticle({})
              setArticleIndex(0)
            }}
          >{findRouter.name}</span>
        </div>

        <div className='contents'>
          {/* 选择Tag */}
          <div className='content-left'>
            <div className="content-left-title">{findRouter.name}</div>
            <ul className='tag-list'>
              {tagData[findRouter.key]?.map((e,i)=>{
                if(e.link || e.link === ''){
                  if(e.link){
                    return (
                      <li 
                        key={i} 
                        className={selectTag?.name === e.name? 'tag-item action':'tag-item'}
                        onClick={()=> setSelectTag(e)}
                      >{e.name}</li>
                      // <a key={i} href={e.link} style={{color:'#000'}} target="_blank">
                      //   <li className={'tag-item'}>{e.name}</li>
                      // </a>
                    )
                  }else{
                    return (<li className={'tag-item'} onClick={()=> alert('敬请期待')}>{e.name}</li>)
                  }
                }else if(e.notes){
                  return <li 
                    key={i} 
                    className={selectTag?.name === e.name? 'tag-item action':'tag-item'}
                    onClick={()=> setSelectTag(e)}
                    >{e.name}</li>
                }else{
                  return (
                    <li 
                      key={i}
                      className={selectTag?.name === e.name? 'tag-item action':'tag-item'}
                      onClick={()=> {
                        setSelectTag(e)
                        if(e.name === '考核咨询'){
                          setSelectArticle(e.data[0])
                          setArticleIndex(0)
                        }else{
                          setSelectArticle({})
                          setArticleIndex(0)
                        }
                      }}
                    >
                      {e.name}
                    </li>
                  )
                }
              })}
            </ul>
          </div>

          {/* 内容列表 */}
          <div className='content-right'>
            {/* {selectAllData} */}
            {!selectTag?.name? 
              <div>
                <div style={{
                  borderBottom: '1px solid #000',
                  lineHeight: '30px',
                  fontWeight:900
                }}>{findRouter.name}</div>
                <List
                  size="large"
                  bordered
                  style={{border:'none'}}
                  dataSource={selectAllData}
                  renderItem={(item,i) => {
                    return (
                      <List.Item 
                        key={i}
                        className='content-right-list-item'
                        onClick={()=>{
                          console.log(tagData[findRouter.key], item)
                          const findTag = tagData[findRouter.key].find(e=> e.key === item.Key)
                          console.log(findTag)
                          setSelectTag(findTag)
                          setSelectArticle(item)
                          console.log('findTag',findTag.data)
                          console.log('index',findTag.data.findIndex(e=> e.id === item.id))
                        }}
                      > 
                        <div 
                          className={`list-item-img-box ${item.img? 'Img':'emptyImg'}`} 
                          style={{backgroundImage: `url(${item.img? item.img:item.defaultImg? item.defaultImg:emptyImg})`}}>
                        </div>
                        <div style={{margin:'20px 0 20px 20px'}}>
                          <div style={{
                            width: 540,
                            fontSize: 20,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'inline-block',
                          }}>{item?.title}</div>
                          <div style={{marginRight: 20}}>{item.time? item.time:''}</div>
                        </div>
                      </List.Item>
                    )
                  }}
                  pagination={{ 
                    position:'bottom', 
                    align:'start',
                    pageSize: 8,
                    showQuickJumper: true
                  }}
                />
              </div>
              :
              selectTag?.content? 
                <div style={{textIndent: 20}}>
                  {selectTag?.content}
                </div>
                :
                !selectArticle.title? 
                  <div>
                    <div style={{
                      borderBottom: '1px solid #000',
                      lineHeight: '30px',
                      fontWeight:900
                    }}>{selectTag.name}</div>
                    <List
                      size="large"
                      bordered
                      style={{border:'none'}}
                      dataSource={selectTag.data}
                      renderItem={(item,i) => {
                        return (
                          <List.Item 
                            key={i}
                            className='content-right-list-item'
                            onClick={()=>{
                              console.log('selectTag',selectTag.data)
                              console.log('index',selectTag.data.findIndex(e=> e.id === item.id))
                              getContentDetailFn(item.id)
                              // setSelectArticle(item)
                              setArticleIndex(selectTag.data.findIndex(e=> e.id === item.id))
                            }}
                          >
                            <div 
                              className={`list-item-img-box ${item.img? 'Img':'emptyImg'}`} 
                              style={{backgroundImage: `url(${item.img? item.img:item.defaultImg? item.defaultImg:emptyImg})`}}>
                            </div>
                            <div style={{margin:'20px 0 20px 20px'}}>
                              <div style={{
                                width: 540,
                                fontSize: 20,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: 'inline-block',
                              }}>{item?.title}</div>
                              <div style={{marginRight: 20}}>{item.time? item.time:''}</div>
                            </div>
                          </List.Item>
                        )
                      }}
                      pagination={{ 
                        position:'bottom', 
                        align:'start',
                        pageSize: 8,
                        showQuickJumper: true
                      }}
                    />
                  </div>
                  :
                  <div className='detail-box'>
                    <div style={{
                      borderBottom: '1px solid #000',
                      lineHeight: '30px',
                      fontWeight:900
                    }}>{selectArticle.title}</div>
                    {/* <div className='contentadddate'>{selectArticle.time}</div> */}
                    <div 
                      style={{paddingBottom: 40, textAlign:'center'}} 
                      dangerouslySetInnerHTML={{ __html: selectArticle.data }} />
                    {/* 翻页 */}
                    {articleIndex+1 > selectTag.data?.length-1? 
                        ''
                        :
                        <div style={{paddingBottom: 10}}>
                          上一篇：
                            <span 
                              style={{cursor: 'pointer'}}
                              onClick={()=>{
                                console.log(selectTag)
                                if(selectTag.data){
                                  getContentDetailFn(selectTag.data[articleIndex+1].id)
                                  // setSelectArticle(selectTag.data[articleIndex+1])
                                  setArticleIndex(articleIndex+1)
                                }
                              }}
                            >{selectTag.data[articleIndex+1].title}</span>  
                        </div>
                    }
                    {articleIndex-1 < 0?
                      ''
                      :
                      <div style={{paddingBottom: 30}}>
                        下一篇：
                          <span
                            style={{cursor: 'pointer'}}
                            onClick={()=>{
                              if(selectTag.data){
                                getContentDetailFn(selectTag.data[articleIndex-1].id)
                                // setSelectArticle(selectTag.data[articleIndex-1])
                                setArticleIndex(articleIndex-1)
                              }
                            }}
                          >{selectTag.data[articleIndex-1].title}</span>
                      
                      </div> 
                    }
                  </div>
            }
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Content